import { template as template_827a70f95cc14739bd83e3a162795e87 } from "@ember/template-compiler";
const FKLabel = template_827a70f95cc14739bd83e3a162795e87(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
