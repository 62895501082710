import { template as template_643a1ce1fb9b4aeabe9a4b6422af3e75 } from "@ember/template-compiler";
const WelcomeHeader = template_643a1ce1fb9b4aeabe9a4b6422af3e75(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
